import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import LoaderScreen from "./pages/Loader/LoaderScreen";
import { useStateContext } from "./contexts/MainContext";
import {
  authRoutes,
  deepLinkingPage,
  errorPage,
  guestRoute,
  layoutRoute,
  siteRoutes,
} from "./routes/Routes";
import { useEffect } from "react";
import AuthLayout from "./containers/authLayout/authLayout";
import RequireAuth from "./components/RequireAuth";
import TestPage from "./pages/TestPage";
import PersistLogin from "./components/PersistLogin";
import { ToastNotification } from "./messages/notificationsHandler";

const App = () => {
  const { setScreenSize } = useStateContext();

  useEffect(() => {
    const calcScreenSize = () => {
      setScreenSize((_prevScreenSize) => window.innerWidth);
    };

    calcScreenSize();
  }, []);

  return (
    <div className="">
      <ToastNotification />
      <Suspense fallback={<LoaderScreen />}>
        <div className="flex relative dark:bg-main-dark-bg">
          <Routes>
            {/* Protected routes */}
            <Route element={<PersistLogin />}>
              <Route element={<RequireAuth />}>
                <Route path={layoutRoute.path} element={layoutRoute.element}>
                  {siteRoutes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                  ))}
                </Route>
              </Route>
            </Route>
            {/* Public routes */}
            <Route path={guestRoute.path} element={guestRoute.element}>
              {authRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Route>
            {/* Error loading routes */}
            <Route path={deepLinkingPage.path} element={deepLinkingPage.element} />
            <Route path={errorPage.path} element={errorPage.element} />
            <Route path="/test" element={<TestPage />} />
          </Routes>
        </div>
      </Suspense>
    </div>
  );
};

export default App;
