import React from "react";
import "./styles.css";
import Banner from "../../assets/pictures/LoaderBanner.gif";

const LoaderScreen = () => {
  return (
    <div className="loader-container">
      <div className="loader-wrapper">
        <div className="blur-effect"></div>
        <div className="spinner-container">
          <img src={Banner} alt="FlexSport" className="spinner-image" />
          <div className="spinner"></div>
        </div>
        <h1>Loading...</h1>
      </div>
    </div>
  );
};

export default LoaderScreen;
