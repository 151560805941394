import React from 'react'
import './styles.css'
import { Link } from 'react-router-dom'
import logo from '../../assets/pictures/citykletalogo.svg'
import aelogo from '../../assets/pictures/aelogo.png'
import { Langs } from '../../langs/langs'


const Footer = () => {
  return (
    <div className="mt-24 footer-container">
    <p className="footer-tab">
      <img src={logo} alt="citykleta" className=' w-[30px]'/> <Link to="https://citykleta.org" target='blank'  className=' text-blue-400'>Citykleta.org</Link> {`© 2022 ${Langs['es'].authUI.rightsReserved}`}
    </p>
    <p className="footer-tab">
      {`${Langs['es'].authUI.poweredBy}`} <img src={aelogo} alt="citykleta" className=' w-[30px]'/> <Link to="https://aleph.engineering" target='blank' className=' text-blue-400'>Cuban Engineer</Link>
    </p>
  </div>
  )
}

export default Footer
