import React from 'react'
import './errorpagestyles.css'
import NotFoundIcon from  '../../assets/pictures/not_found_icon.png'
import { Link } from 'react-router-dom'
import { Langs } from '../../langs/langs'

const ErrorPage = () => {
  return (
    <div className='notfound-container'>
      <div className="notfound-container-left">
        <img src={NotFoundIcon} alt="" className=' w-[80%] h-[80%]'/>
      </div>
      <div className="notfound-container-right">
        <div className="notfound-container-right-header">
          <h3>{Langs['es'].globalUI.pageNotFound}</h3>
        </div>
        <div className="notfound-container-right-body">
          <h2>Uh Oh! Error 404</h2>
          <p className="">
            {Langs['es'].globalUI.errorPageText}
          </p>
        </div>
        <div className="notfound-container-right-actions">
          <Link to="/" ><span>{Langs['es'].globalUI.backToSite}</span></Link>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
