// import dotenv from "dotenv";
// const path = require("path");

// dotenv.config();
// const _dev_env = process.env.NODE_ENV ? `.env.${process.env.NODE_ENV}`.trim() : ".env";


// // load .env environment variables
// dotenv.config({
//   path: path.resolve(process.cwd(), _dev_env)
// });
// import { loadEnv } from "../env-handler/env-handler";

// await loadEnv();
// console.log(process.env.API_URL);

//local
// export const config = {
//   API_URL: "http://localhost:3838/",
//   APP_SECRET_KEY: "citykleta123",
//   cancelColor: "#d55c5c",
//   eventColor: "rgb(234 179 8 / 1)",
//   acceptColor: "#5cd58b",
//   MapBoxToken: "pk.eyJ1IjoiZXhhZ29uc29mdCIsImEiOiJjbHA3eHNlZXEyN3puMmlwYnVncmN5YnVyIn0.UBVy6rikx99KtbY69_N_kg",
// };

//Production
export const config = {
  API_URL: "https://api.citykleta.aleph.engineering/",
  APP_SECRET_KEY: "abc123",
  cancelColor: "#d55c5c",
  eventColor: "rgb(234 179 8 / 1)",
  acceptColor: "#5cd58b",
  MapBoxToken: "pk.eyJ1IjoiZXhhZ29uc29mdCIsImEiOiJjbHA3eHNlZXEyN3puMmlwYnVncmN5YnVyIn0.UBVy6rikx99KtbY69_N_kg",
};