import React from 'react'
import UseRefreshToken from '../hooks/useRefreshToken'
import LoaderScreen from './Loader/LoaderScreen';
import ErrorPage from './Error/errorPage';

const TestPage = () => {
    const refresh = UseRefreshToken();
  return (
    <div className=' bg-green-600'>
      <button onClick={() => refresh()} >Refresh</button>
      <ErrorPage />
    </div>
  )
}

export default TestPage
