import Cookies from "js-cookie";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  not: false,
  prof: false,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#eab308");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [auth, setAuth] = useState({});
  const navigate = useNavigate();
  const [eventEmitted, setEventEmitted] = useState(0);

  

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
  };

  const authStorage = (credentials) => {
    Cookies.set("user", credentials);
    setAuth({ accessToken: credentials });
  };

  const logOut = () => {
    Cookies.set("user", null);
    setAuth({});
    setIsClicked(initialState);
    navigate("/info/signin", { replace: true });
  };

  const handleClick = (clicked) =>
    setIsClicked({ ...initialState, [clicked]: true });

  const freezeScreen = () => {
    document.body.style.overflowY = "hidden";
  };

  const unFreezeScreen = () => {
    document.body.style.overflowY = "auto";
  };

  const emitEvent = () => {
    if(eventEmitted < 30000){
      setEventEmitted(eventEmitted + 1);
    }else{
      setEventEmitted(0);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      emitEvent();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [eventEmitted]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        auth,
        eventEmitted,
        authStorage,
        setAuth,
        setScreenSize,
        logOut,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        freezeScreen,
        unFreezeScreen,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
