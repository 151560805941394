import { useEffect, useState } from "react";
import { useStateContext } from "../contexts/MainContext";
import useRefreshToken from "../hooks/useRefreshToken";
import { Outlet } from "react-router-dom";
import LoaderScreen from "../pages/Loader/LoaderScreen";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useStateContext();

  const verifyRefreshToken = async () => {
    try {
      await refresh();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
  }, []);

  return <>{isLoading ? <LoaderScreen /> : <Outlet />}</>;
};

export default PersistLogin;
