export const Langs = {
  en: {
    authUI: {
      cancel: "Cancel",
      ok: "Accept",
    },
    usersUI: {
      category: "Users",
      listTitle: "Users List",
      btnNewUser: "New User",
      btnEditUser: "Edit User",
      btnDeleteUser: "Delete User",
    },
    globalUI: {
      userDeleted: "The user has been deleted successfully",
    },
  },
  es: {
    authUI: {
      cancel: "Cancelar",
      ok: "Aceptar",
      update: "Modificar",
      add: "Adicionar",
      rightsReserved: "Todos los derechos reservados",
      poweredBy: "Desarrollado por",
      loginTitle: "Entre sus Credenciales",
      loginActionButton: "Autenticar",
      loginResetLink: "No recuerdas tu contraseña, Resetear",
      resetTitle: "Cambio de credenciales",
      resetActionButton: "Solicitar cambio",
      resetResetLink: "Lo recordaste, Login",
      sendOtpAction: "Enviar Codigo OTP",
      securityNoteTitle: "Nota de seguridad:",
      securityNote:
        "Por razones de seguridad, se recomienda cambiar su \n\
contraseña después de iniciar sesión. Puede hacerlo visitando su \n\
Configuraciones de la cuenta.",
      issueEncounter:
        "Si encuentra algún problema o no solicitó un restablecimiento de contraseña, \n\
                 Comuníquese con nuestro equipo de soporte de inmediato.",
      backToLogin: "Regresar a Login",
      newCredentialsTitle: "Use sus nuevas credenciales:",
      newCredentialsTip:
        "Ingrese su dirección de correo electrónico registrada y la nueva contraseña enviada \n\
      a tu correo electrónico.",
      resetSuccess: "Cambio de contraseña exitoso.",
      resetSuccessNotification:
        "Su contraseña se ha restablecido correctamente. Una nueva contraseña aleatoria \n\
      ha sido generado y enviado a su dirección de correo electrónico registrada. \n\
      Por favor revise su bandeja de entrada y use la nueva contraseña para iniciar sesión.",
      resetTipsTitle: "Instrucciones",
      resetTip1title: "Revice su email: ",
      resetTip1tip:
        "Vaya a la bandeja de entrada de su correo electrónico y busque un correo electrónico nuestro. El\n\
                        La línea de asunto contendrá información sobre su contraseña.\n\
                        reiniciar.",
      resetTip2title: "Autenticarce: ",
      resetTip2tip:
        "Regrece a la forma de autenticacion e ingrese las nuevas credenciales.",
    },
    sideBarUI: {
      dashBoard: "Panel Principal",
      groups: "Grupos",
      users: "Usuarios",
      admins: "Administradores",
      owners: "Propietarios",
      bikes: "Bicicletas",
      handleBikes: "Administrar Bicicletas",
      bike_accessories: "Accesorios",
      bike_tags: "Etiquetas",
      reservations: "Reservaciones",
      handleReservations: "Administrar Reservaciones",
      statistics: "Finanzas",
      notifications: "Notificaciones",
      messages: "Lista de mensages",
      siteMessages: "Notificaciones de Soporte",
      announces: "Anuncios",
      transactions: "Historial de Transacciones",
      income: "Balances Generales",
      deductions: "Enmendar Balance",
      settings: "Ajustes",
      generalSettings: "Ajustes Generales",
      logs: "Registros del Sistema",
    },
    usersUI: {
      category: "Usuarios",
      listTitle: "Lista de Usuarios",
      btnNewUser: "Nuevo Usuario",
      btnEditUser: "Editar Usuario",
      btnDeleteUser: "Eliminar Usuario",
      gridHeads: {
        name: "Nombre",
        mobile: "Número de teléfono",
        is_verified: "¿Esta verificado?",
        id: "Id del Usuario",
      },
      placeHolders: {
        firstName: "Introduzca nombre del usuario...",
        lastName: "Introduzca apellidos del usuario...",
        email: "Introduzca email del usuario...",
        password: "Introduzca contrasena del usuario...",
        mobile: "Introduzca número de telefono del usuario...",
        lat: "Latitud Aproximada...",
        lng:"Longitud Aproximada...",
        is_Verified: "¿Esta verificado?",
        is_owner: "¿Es usuario Propietario?",
      },
    },
    messagesUI: {
      category: "Notificaciones",
      listTitle: "Lista de Mensages",
      btnNewMessage: "Nuevo Mensage",
      btnReadMessage: "Leer Mensage",
      btnDeleteMessage: "Eliminar Mensage",
      gridHeads: {
        user_from: "Usuario Remitente",
        user_to: "Usuario Destinatario",
        read: "¿Leido?",
        date: "Fecha de envio",
        title: "Titulo",
        id: "Id Unico",
      },
      placeHolders: {
        title: "Titulo...",
        message: "Mensage...",
      },
    },
    siteMessagesUI: {
      category: "Notificaciones",
      listTitle: "Lista de Notificaciones",
      btnNewMessage: "Nuevo Mensage",
      btnReadMessage: "Leer Mensage",
      btnDeleteMessage: "Eliminar Mensage",
      gridHeads: {
        user_from: "Usuario Remitente",
        user_to: "Usuario Destinatario",
        read: "¿Leido?",
        date: "Fecha de envio",
        title: "Titulo",
        id: "Id Unico",
      },
      placeHolders: {
        title: "Introduzca el titulo del mensage...",
        message: "Introduzca el cuerpo del mensage...",
      },
    },
    announcementsUI: {
      category: "Notificaciones",
      listTitle: "Lista de Anuncios",
      btnNewAnnouncement: "Nuevo Anuncio",
      btnEditAnnouncement: "Editar Anuncio",
      btnDeleteAnnouncement: "Eliminar Anuncio",
      gridHeads: {
        picture: "Imagen",
        date: "Fecha de Creacion",
        title: "Titulo",
        link: "Vinculo",
        id: "Id Unico",
      },
      placeHolders: {
        title: "Titulo...",
        link: "Vinculo...",
        message: "Mensage...",
      },
    },
    reservationsUI: {
      category: "Reservaciones",
      listTitle: "Lista de Reservaciones",
      btnEditAnnouncement: "Actualizar Reservacion",
      gridHeads: {
        user: "Usuario",
        byke: "Bicicleta",
        startDate: "Hora de Recogida",
        endDate: "Hora de Entrega",
        status: "Estado",
        date: "Fecha de Creacion",
      },
      placeHolders: {
        status: "Estado Actual...",
        penalized: "Devolver Contribucion",
        returnRent: "Devolver Costo de Reservacion",
        ownerEmail: "Email",
        ownerName: "Propietario",
        ownerMobile: "Telefono"
      },
    },
    ownersUI: {
      category: "Propietarios",
      listTitle: "Lista de Propietarios",
      btnNewOwner: "Nuevo Propietario",
      btnEditOwner: "Editar Propietario",
      btnDeleteOwner: "Eliminar Propietario",
      ownerAvailability: "Horario del Propietario",
      ownerAvailabilityDay: "Día",
      ownerAvailabilityOpen: "Desde",
      ownerAvailabilityClose: "Hasta",
      gridHeads: {
        name: "Nombre",
        mobile: "Número de teléfono",
        is_verified: "¿Esta verificado?",
        id: "Id del Propietario",
      },
      placeHolders: {
        address: "Dirección del propietario...",
        bio: "Pequeño resumen del propietario...",
        account: "Número de cuenta del propietario...",
        createdDate: "Fecha de creación...",
        is_Verified: "¿Esta verificado como Business?",
      },
    },
    bikesUI: {
      category: "Bicicletas",
      listTitle: "Lista de Bicicletas",
      btnNewBike: "Nueva Bicicleta",
      btnEditBike: "Editar Bicicleta",
      btnDeleteBike: "Eliminar Bicicleta",
      bikeAvailability: "Horario de la Bicicleta",
      bikeAvailabilityDay: "Día",
      bikeAvailabilityOpen: "Desde",
      bikeAvailabilityClose: "Hasta",
      gridHeads: {
        name: "Nombre",
        model: "Modelo",
        brand: "Marca",
        size: "Tamaño",
        owner: "Propietario",
        hourly_rate: "Precio /hora",
        daily_rate: "Precio /día",
        is_verified: "¿Esta verificado?",
        id: "Id único",
      },
      placeHolders: {
        owner: "Seleccione propietario...",
        hourly_rate: "Especifique costo /h...",
        daily_rate: "Especifique costo /día...",
        min_rental_price: "Costo mínimo de renta...",
        name: "Especifique el nombre...",

        address: "Especifique la Dirección...",
        size: "Especifique el tamaño...",
        min_height: "Especifique alto mínimo...",
        max_height: "Especifique alto máximo...",
        brand: "Especifique la marca...",

        model: "Especifique el modelo...",
        description: "Pequeño resumen de la bicicleta...",
        bike_accessories: "Seleccione accesorios...",
        bike_images: "Imágenes de la bicicleta...",
        bike_tags: "Seleccione las etiquetas...",
      },
    },
    bikeAccessoriesUI: {
      category: "Bicicletas",
      listTitle: "Lista de Accesorios",
      btnNewAccessory: "Nuevo Accesorio",
      btnEditAccessory: "Editar Accesorio",
      btnDeleteAccessory: "Eliminar Acsesorio",
      gridHeads: {
        name: "Nombre",
        id: "Id único",
      },
      placeHolders: {
        name: "Accesorio...",
      },
    },
    bikeTagsUI: {
      category: "Bicicletas",
      listTitle: "Lista de Etiquetas",
      btnNewTag: "Nueva Etiqueta",
      btnEditTag: "Editar Etiqueta",
      btnDeleteTag: "Eliminar Etiqueta",
      gridHeads: {
        name: "Etiqueta",
        id: "Id Unico",
      },
      placeHolders: {
        name: "Etiqueta...",
      },
    },
    adminsUI: {
      category: "Administradores",
      listTitle: "Lista de Administradores",
      btnNewAdmin: "Nuevo Administrador",
      btnEditAdmin: "Editar Administrador",
      btnDeleteAdmin: "Eliminar Administrador",
      gridHeads: {
        name: "Nombre",
        mobile: "Número de teléfono",
        is_verified: "Esta Verificado",
        id: "Id del Administrador",
      },
      placeHolders: {
        firstName: "Entre el nombre del administrador...",
        lastName: "Entre el Apellido del administrador...",
        email: "Entre el Email del administrador...",
        password: "Entre la contrasena para el administrador...",
        mobile: "Entre el Número de telefono del administrador...",
        is_Verified: "¿Esta verificado?",
      },
    },
    settingsUI: {
      category: "Ajustes",
      listTitle: "Lista de Ajustes",
      btnAddSetting: "Nueva Variable",
      btnEditSetting: "Actualizar Variable",
      gridHeads: {
        name: "Nombre de Variable",
        value: "Valor de la variable",
        id: "Id Unico"
      },
      placeHolders: {
        key: "Variable: ",
        value: "Valor: ",
        key_name: "Identificador unico de la variable...",
        key_value: "Asignar valor a la variable..."
      },
    },
    balancesUI: {
      category: "Finanzas",
      listTitle: "Lista de Balances por Usuario",
      btnAddSetting: "Nueva Variable",
      btnEditSetting: "Actualizar Variable",
      gridHeads: {
        user: "Usuario",
        balance: "Balance Actual ($)",
        id: "Id Unico"
      },
      placeHolders: {
        key: "Variable: ",
        value: "Valor: ",
        key_name: "Identificador unico de la variable...",
        key_value: "Asignar valor a la variable..."
      },
    },
    transactionsUI: {
      category: "Finanzas",
      listTitle: "Lista de Transacciones",
      amendTransactionTitle: "Enmendar Balance",
      gridHeads: {
        userName: "Usuario",
        type: "Tipo de Transaccion",
        amount: "Monto",
        date: "Fecha",
      },
      placeHolders: {
        amount: "Monto a enmendar",
        user: "Usuario",
        description: "Razon de la correccion",
        type: "Tipo de Transaccion",
      },
    },
    logsUI: {
      category: "Ajustes",
      listTitle: "Lista de Registros del Sistema",
      btnView: "Ver Detalles",
      gridHeads: {
        type: "Tipo de Registro",
        title: "Titulo",
        message: "Mensage",
        date: "Fecha",
      },
      placeHolders: {
        amount: "Monto a enmendar",
        user: "Usuario",
        description: "Razon de la correccion",
        type: "Tipo de Transaccion",
      },
    },
    globalUI: {
      success: "Operación realizada correctamente",
      userDeleted: "El usuario ha sido eliminado correctamente",
      userCreated: "El usuario ha sido creado correctamente",
      userModified: "El usuario ha sido modificado correctamente",
      nothingSelected: "Por favor, seleccione un elemento",
      multipleSelected: "Debe seleccionar solo un elemento",
      modalDialogText:
        "Esta acción no se puedee deshacer, ¿seguro quiere proceder?",
      pageNotFound: "Pagina Desconocida",
      backToSite: "Regresar al Panel Principal",
      errorPageText:
        "Parece que te has perdido; No te preocupes, podemos ayudarte. Simplemente haga clic en el botón de abajo para volver a la página de inicio 😃.",
      noData: "Sin Datos...",
      adddImage: "Añadir Imágen",
      is_featured_image: "¿Es principal?",
      readed: "Leido",
      verified: "Verificado",
      ownerVerified: "Negocio Verificado",
      pending: "Pendiente",
      send: "Enviar",
    },
  },
};
