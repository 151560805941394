import { useStateContext } from "../contexts/MainContext";
import { Validate, ValidateRefresh, Sign, Decode } from "shieldbearer";
import axios from "../api/axios";
import Cookies from "js-cookie";
import { config } from "../config/config";

const useRefreshToken = () => {
  const { setAuth } = useStateContext();

  const refresh = async () => {
    try {
      const _authUser = Cookies.get('user');
      if(!_authUser) {
        setAuth({});
      }

      const _token = _authUser;
      const _isValid = ValidateRefresh(_token, config.APP_SECRET_KEY);

      if(!_isValid) {
        setAuth({});
      }

      const _userFromToken = Decode(_token, config.APP_SECRET_KEY);

      const _tokenData = {
        id: _userFromToken.id,
        email: _userFromToken.email,
        firstName: _userFromToken.firstName,
        lastName: _userFromToken.lastName,
        picture: _userFromToken.picture,
      };

      const _newAccessToken = Sign(_tokenData, config.APP_SECRET_KEY);

      setAuth((_prevAuth) => {
        return { ..._prevAuth, accessToken: _newAccessToken };
      });
      return _newAccessToken;
    } catch (error) {
      console.log(error);
    }
  };

  return refresh;
};

export default useRefreshToken;
