import { lazy } from "react";

// Lazy load the components
const Layout = lazy(() => import("../containers/mainLayout/mainLayout"));
const AuthLayout = lazy(() => import("../containers/authLayout/authLayout"));

const DashBoard = lazy(() => import("../pages/Dashboard/ECommerce"));

const Users = lazy(() => import("../pages/Users/Users"));
const UsersHandle = lazy(() => import("../pages/Users/UserHandle"));

const Admins = lazy(() => import("../pages/Admins/adminsPage"));
const AdminsHandle = lazy(() => import("../pages/Admins/adminsHandle"));

const Owners = lazy(() => import("../pages/Owners/Owners"));
const OwnersHandle = lazy(() => import("../pages/Owners/OwnerHandle"));

const Bikes = lazy(() => import("../pages/Bikes/Bikes"));
const BikesHandle = lazy(() => import("../pages/Bikes/BikeHandle"));

const Accessories = lazy(() => import("../pages/BikeAccessorie/BikeAccessories"));
const AccessoriesHandle = lazy(() => import("../pages/BikeAccessorie/BikeAccessoriesHandle"));

const Tags = lazy(() => import("../pages/BikeTags/BikeTags"));
const TagsHandle = lazy(() => import("../pages/BikeTags/BikeTagsHandle"));

const Renters = lazy(() => import("../pages/Renters/Renters"));

const Business = lazy(() => import("../pages/Business/Business"));

const Transactions = lazy(() => import("../pages/Transactions/Transactions"));

const AmendTransactions = lazy(() => import("../pages/Transactions/AmendTransactions"));

const GeneralBalances = lazy(() => import("../pages/GeneralBalances/generalBalancesPage"));

const Messages = lazy(() => import("../pages/Notifications/Messages"));
const SiteMessages = lazy(() => import("../pages/Notifications/SiteNotifications"));

const Announcements = lazy(() => import("../pages/Announcements/Announcements"));
const AnnouncementsHandler = lazy(() => import("../pages/Announcements/AnnouncementsHandler"));

const Reservations = lazy(() => import("../pages/Reservations/ReservationsPage"));

const Settings = lazy(() => import("../pages/Settings/settingsPage"));

const SystemLogs = lazy(() => import("../pages/SystemLogs/SystemLogs"));

const LogIn = lazy(() => import("../pages/Auth/LoginPage"));
const Reset = lazy(() => import("../pages/Auth/ResetPasswordPage"));

const DeepLinking = lazy(() => import("../pages/deepLinking/deepLinking"));

const ErrorPage = lazy(() => import("../pages/Error/errorPage"));

export const layoutRoute = { path: "/", element: <Layout /> };
export const guestRoute = { path: "/info", element: <AuthLayout /> };

export const errorPage = { path: "*", element: <ErrorPage /> };

export const deepLinkingPage = { path: "/deep_linking" , element: <DeepLinking />}

export const authRoutes = [
  { path: "/info/signin", element: <LogIn /> },
  { path: "/info/reset", element: <Reset /> },
];

export const siteRoutes = [
  // Replace with Dashboard for next Stage
  { path: "/", element: <Admins /> },
  { path: "/users", element: <Users /> },
  { path: "/users/handle_users", element: <UsersHandle /> },
  { path: "/admins", element: <Admins /> },
  { path: "/admins/handle_admins", element: <AdminsHandle /> },
  { path: "/owners", element: <Owners /> },
  { path: "/owners/handle_owners", element: <OwnersHandle /> },
  { path: "/bikes", element: <Bikes /> },
  { path: "/bikes/handle_bikes", element: <BikesHandle /> },
  { path: "/accessories", element: <Accessories /> },
  { path: "/accessories/handle_accessories", element: <AccessoriesHandle /> },
  { path: "/tags", element: <Tags /> },
  { path: "/tags/handle_tags", element: <TagsHandle /> },
  { path: "/renters", element: <Renters /> },
  { path: "/business", element: <Business /> },
  { path: "/transactions", element: <Transactions /> },
  { path: "/amend_transactions", element: <AmendTransactions /> },
  { path: "/balances", element: <GeneralBalances /> },
  { path: "/messages", element: <Messages /> },
  { path: "/site_messages", element: <SiteMessages /> },
  { path: "/announcements", element: <Announcements /> },
  { path: "/announcements/announcements_handler", element: <AnnouncementsHandler /> },
  { path: "/reservations", element: <Reservations /> },
  { path: "/general_settings", element: <Settings /> },
  { path: "/system_logs", element: <SystemLogs /> },
];
