import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useStateContext } from "../contexts/MainContext"


const RequireAuth = () => {
    const { auth }= useStateContext();
    const location = useLocation();

    return (
        auth?.accessToken
            ? <Outlet />
            : <Navigate to='/info/signin' state={{ from: location}} replace />
    );
}

export default RequireAuth