// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.footer-tab{
    border-radius: 8px 8px 0 0;
    color: white;
    background: black;
    text-align: center;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    gap: 5px;
}

@media screen and (max-width:680px) {
    .footer-container{
        flex-direction: column;
    }
    
    .footer-tab{
        border-radius: 0;
        justify-content: center;
        font-size: small;
    }
}

/* dark:text-gray-700  text-gray-200 bg-gray-600 dark:bg-gray-200 text-center mx-20 flex items-center gap-2 px-16 py-4  */`, "",{"version":3,"sources":["webpack://./src/components/Footer/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,WAAW;IACX,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,0BAA0B;IAC1B,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,gBAAgB;QAChB,uBAAuB;QACvB,gBAAgB;IACpB;AACJ;;AAEA,yHAAyH","sourcesContent":[".footer-container{\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    width: 100%;\n    display: flex;\n    justify-content: space-around;\n}\n\n.footer-tab{\n    border-radius: 8px 8px 0 0;\n    color: white;\n    background: black;\n    text-align: center;\n    padding: 8px 20px;\n    display: flex;\n    align-items: center;\n    gap: 5px;\n}\n\n@media screen and (max-width:680px) {\n    .footer-container{\n        flex-direction: column;\n    }\n    \n    .footer-tab{\n        border-radius: 0;\n        justify-content: center;\n        font-size: small;\n    }\n}\n\n/* dark:text-gray-700  text-gray-200 bg-gray-600 dark:bg-gray-200 text-center mx-20 flex items-center gap-2 px-16 py-4  */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
