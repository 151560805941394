import {
  AiFillDashboard,
  AiOutlineUser,
  AiFillSetting,
  AiFillFileText,
  AiFillCrown,
  AiFillMessage,
} from "react-icons/ai";
import { FaObjectUngroup, FaTags, FaCogs } from "react-icons/fa";
import { RiNotification3Line } from "react-icons/ri";
import { BsBicycle } from "react-icons/bs";
import { BiCalendar, BiMoneyWithdraw } from "react-icons/bi";
import { MdMonetizationOn } from "react-icons/md";
import { GrAnnounce } from "react-icons/gr";
import { GiPayMoney } from "react-icons/gi";
import { Langs } from "../langs/langs";

export const sideLinks = [
  // {
  //   title: Langs["es"].sideBarUI.dashBoard,
  //   links: [
  //     {
  //       name: Langs["es"].sideBarUI.dashBoard,
  //       urlAddress: "/",
  //       icon: <AiFillDashboard />,
  //     },
  //   ],
  // },
  {
    title: Langs["es"].sideBarUI.groups,
    links: [
      {
        name: Langs["es"].sideBarUI.admins,
        urlAddress: "/admins",
        icon: <AiFillCrown />,
      },
      {
        name: Langs["es"].sideBarUI.owners,
        urlAddress: "/owners",
        icon: <FaObjectUngroup />,
      },
      {
        name: Langs["es"].sideBarUI.users,
        urlAddress: "/users",
        icon: <AiOutlineUser />,
      },
    ],
  },
  {
    title: Langs["es"].sideBarUI.bikes,
    links: [
      {
        name: Langs["es"].sideBarUI.handleBikes,
        urlAddress: "/bikes",
        icon: <BsBicycle />,
      },
      {
        name: Langs["es"].sideBarUI.bike_accessories,
        urlAddress: "/accessories",
        icon: <FaCogs />,
      },
      {
        name: Langs["es"].sideBarUI.bike_tags,
        urlAddress: "/tags",
        icon: <FaTags />,
      },
    ],
  },
  {
    title: Langs["es"].sideBarUI.reservations,
    links: [
      {
        name: Langs["es"].sideBarUI.handleReservations,
        urlAddress: "/reservations",
        icon: <BiCalendar />,
      },
    ],
  },
  {
    title: Langs["es"].sideBarUI.statistics,
    links: [
      {
        name: Langs["es"].sideBarUI.transactions,
        urlAddress: "/transactions",
        icon: <GiPayMoney />,
      },
      {
        name: Langs["es"].sideBarUI.deductions,
        urlAddress: "/amend_transactions",
        icon: <MdMonetizationOn />,
      },
      {
        name: Langs["es"].sideBarUI.income,
        urlAddress: "/balances",
        icon: <BiMoneyWithdraw />,
      },
    ],
  },
  {
    title: Langs["es"].sideBarUI.notifications,
    links: [
      {
        name: Langs["es"].sideBarUI.messages,
        urlAddress: "/messages",
        icon: <AiFillMessage />,
      },
      {
        name: Langs["es"].sideBarUI.siteMessages,
        urlAddress: "/site_messages",
        icon: <RiNotification3Line />,
      },
      {
        name: Langs["es"].sideBarUI.announces,
        urlAddress: "/announcements",
        icon: <GrAnnounce />,
      },
    ],
  },
  {
    title: Langs["es"].sideBarUI.settings,
    links: [
      {
        name: Langs["es"].sideBarUI.generalSettings,
        urlAddress: "/general_settings",
        icon: <AiFillSetting />,
      },
      {
        name: Langs["es"].sideBarUI.logs,
        urlAddress: "/system_logs",
        icon: <AiFillFileText />,
      },
    ],
  },
];

export const devicesType = {
  desktop: 1200,
  tablet: 992,
  mobile: 768,
};

export const notificationType = {
  success: "SUCCESS",
  warning: "WARNING",
  error: "ERROR",
  info: "INFO",
};

export const logType = {
  notification_log: "Notification",
  warning_log: "Warning",
  error_log: "Error",
};

export const reservationStatus = {
  pre_reserved: 1,
  pending: 2,
  confirmed: 3,
  cancelled: 4,
  in_progress: 5,
  delayed_return: 6,
  completed: 7,
}; //TODO: This is harcoded... should use the reservationStatus from database

export const reservationStatusIcons = {
  1: "💲",
  2: "⏳",
  3: "✅",
  4: "⛔",
  5: "🚲",
  6: "🐌",
  7: "🎌",
};

export const defaultLocation = {
  lat: 23.139697,
  long: -82.36022699999999,
};

export const timeZoneOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: true,
  timeZone: "UTC",
};
